@import "app/style/index";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  background: #f7f8fe;
}

.mat-fab.mat-primary:not(:hover),
.mat-flat-button.mat-primary:not(:hover),
.mat-mini-fab.mat-primary:not(:hover),
.mat-raised-button.mat-primary:not(:hover) {
  background-color: #1c3d9f;
}

.btn-info:not(:hover) {
  background-color: #00a3d9;
}

.mat-primary .mat-slider-track-fill {
  background-color: #1c3d9f;
}

.mat-primary .mat-slider-thumb {
  background-color: #1c3d9f;
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
  background-color: #1c3d9f;
}

.mat-primary .mat-slider-thumb-label {
  background-color: #1c3d9f;
}

.mat-slider-thumb {
  width: 30px !important;
  height: 30px !important;
  border: white solid 5px !important;
  box-shadow: 0 3px 6px rgba(45, 45, 45, 0.3);
}

.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 5px !important;
  border-radius: 5px !important;
}

.mat-slider-horizontal .mat-slider-track-background {
  height: 5px !important;
}

.mat-slider-horizontal .mat-slider-track-fill {
  height: 5px !important;
}

.mat-slider-horizontal .mat-slider-wrapper {
  height: 13px !important;
}

.mat-slide-toggle-thumb {
  height: 30px !important;
  width: 30px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
}

.mat-slide-toggle-bar {
  width: 60px !important;
  height: 30px !important;
  border-radius: 15px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(30px, 0, 0) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #1c3d9f;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  // background-color: #1D84C6;
  background-color: white;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: white;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #1c3d9f;
}

.mat-radio-outer-circle {
  border-width: 3px !important;
  box-shadow: 0 3px 6px rgba(45, 45, 45, 0.3);
  background-color: #1d84c6;
  border-color: #1d84c6;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6) !important;
  box-shadow: 0 3px 6px rgba(45, 45, 45, 0.3);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1c3d9f !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 5px !important;
  border: 0.5px solid;
}

.mat-checkbox-label {
  white-space: normal;
}

.mat-form-field-flex {
  background-color: white;
  padding-left: 20px;
  padding-right: 10px;
  height: 50px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: unset !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: unset;
}

.list-full-prepayment .mat-checkbox-layout .mat-checkbox-label {
  font-size: 18px;
}

.mat-datepicker-toggle {
  float: right;
  margin-top: -45px;
}

.cdk-overlay-container {
  z-index: 1050;
}

.success-snackbar {
  color: greenyellow;
}

.error-snackbar {
  color: red;
}

.error-mat-checkbox .mat-checkbox-inner-container .mat-checkbox-background {
  border-color: red;
  border-width: 2px;
}

.logo {
  cursor: pointer;
  img {
    @media screen and (max-width: 425px) {
      max-width: 210px;
    }
    max-width: 300px;
    width: fit-content;
    height: 30px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-content {
  .close-block {
    img {
      background: #eb5757;
      padding: 7px;
      border-radius: 100px;
    }
  }
}

input,
select {
  /* White color */
  background: #ffffff;
  /* Second text color */
  border: 1px solid #778a9a;
  box-sizing: border-box;
  border-radius: 10px;
}
