$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1210px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1190px,
);

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

$form-group-margin-bottom: 30px !default;

$modal-md: 600px !default;

$input-btn-padding-y: 0.8125rem !default;
$input-btn-padding-x: 1.25rem !default;

$input-border-color: transparent !default;
$input-border-radius: 0px !default;

@import "~bootstrap/scss/bootstrap";

.form-control:focus {
  box-shadow: none;
}
